import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import '../assets/sass/main.scss'

const Layout = ({ children }) => ( <
    StaticQuery query = { graphql `
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    ` }
    render = {
        data => ( <
            >
            <
            Helmet title = { data.site.siteMetadata.title }
            meta = {
                [
                    { name: 'description', content: 'Luis Mexin personal site' },
                    { name: 'keywords', content: 'programming, coding, mexico, js, react' },
                ]
            } >
            <
            html lang = "en" / >
            <
            /Helmet> <
            div id = "wrapper" > { children } < /div> <
            />
        )
    }
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout